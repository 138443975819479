<template>
  <b-container fluid class="my-3">
    <h2 class="align-self-center">{{ $t('invoices.name') }}</h2>
    <b-card bg-variant="light">
      <b-row>
        <b-col md="2">
          <b-form-group :label="$t('jobs.filter.date-from') + ':'">
            <date-picker
              v-e2e:invoicesFilterDateFrom
              v-model="filterDateFrom"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group :label="$t('jobs.filter.date-to') + ':'">
            <date-picker v-e2e:invoicesFilterDateTo v-model="filterDateTo" />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group :label="$t('jobs.filter.status') + ':'">
            <b-form-select
              v-e2e:invoicesFilterStatus
              v-model="filterStatus"
              :options="statuses"
            >
              <template slot="first">
                <option :value="null">-- {{ $t('all') }} --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group class="mb-0">
            <template slot="label">
              {{ $t('invoices.file-package.label') }}:
            </template>
            <b-btn
              v-e2e:invoicesDownloadReportButton
              id="download-report-btn"
              block
              @click="downloadReport"
              :disabled="downloadDisabled"
              v-b-popover.bottom="$t('invoices.file-package.explain')"
            >
              {{ $t('invoices.file-package.generate') }}
            </b-btn>
            <small class="text-muted">
              {{ $t('jobs.file-package.count', { totalRows }) }}
            </small>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <div>
      <b-table
        v-e2e:invoicesTable
        :items="fetchInvoices"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDir"
        ref="invoicesTable"
      >
        <template #cell(issue_date)="{ item }">
          {{ item.invoice.issue_date | moment('L') }}
        </template>
        <template #cell(status)="{ item }">
          {{ $t(`invoices.statuses.${item.invoice.status.toLowerCase()}`) }}
        </template>
        <template #cell(total_amount)="{ item }">
          {{ `&euro; ${item.invoice.total_amount.toFixed(2)}` }}
        </template>
        <template #cell(download)="{ item }">
          <a
            :href="`/api/invoice/download/${item.invoice.path.replace(
              'documents-api/file',
              '',
            )}?jwt=${access_token}`"
            target="blank"
          >
            <strong>Download</strong>
          </a>
        </template>
      </b-table>
      <b-pagination
        v-e2e:invoicesPagination
        :total-rows="totalRows"
        :per-page="perPage"
        v-model="currentPage"
        align="center"
      />
    </div>
  </b-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { mapFilters } from '@gid/vue-common/store/utils';
import DatePicker from '@gid/vue-common/components/DatePicker.vue';

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      loadingInvoices: null,
      fields: [
        {
          label: this.$t('invoices.table.heading.issuing-date'),
          key: 'issue_date',
        },
        { label: this.$t('invoices.table.heading.status'), key: 'status' },
        {
          label: this.$t('invoices.table.heading.grand-total'),
          key: 'total_amount',
        },
        {
          label: this.$t('invoices.table.heading.download'),
          key: 'download',
        },
      ],
      currentPage: 1,
      currentPageCount: null,
      perPage: 10,
      totalRows: 0,
      sortBy: null,
      sortDir: false,
      downloadStarted: false,
      statuses: [
        { value: 'sent', text: this.$t('invoices.statuses.sent') },
        { value: 'paid', text: this.$t('invoices.statuses.paid') },
        {
          value: 'cancellation_sent',
          text: this.$t('invoices.statuses.cancelled'),
        },
        { value: 'received', text: this.$t('invoices.statuses.received') },
      ],
    };
  },
  computed: {
    ...mapGetters(['locale', 'access_token']),
    ...mapFilters({
      moduleName: 'invoices',
      states: ['dateFrom', 'dateTo', 'status'],
      setMutation: 'SET_REPORTS_FILTER',
      setCallback: 'filterUpdated',
    }),
    accessToken() {
      return this.access_token;
    },
    downloadDisabled() {
      return this.totalRows == 0 || this.downloadStarted;
    },
  },
  methods: {
    async fetchInvoices({ perPage, currentPage }) {
      const searchQuery = {
        page_size: perPage,
        page_number: currentPage - 1,
        from_date: this.filterDateFrom,
        to_date: this.filterDateTo,
        status: this.filterStatus,
        sort_by: 'invoice_date', //this.sortBy ? this.sortBy : null,
        sort_dir: 'desc', //this.sortDir ? 'asc' : 'desc',
      };

      return axios
        .get(`/api/brand/invoices`, { params: searchQuery })
        .then((response) => {
          this.totalRows = response.data.pagination.total_count;
          this.currentPageCount = response.data.data.length;
          return response.data.data;
        })
        .catch(() => []);
    },
    filterUpdated() {
      this.currentPage = 1;
      this.downloadStarted = false;
      this.$nextTick(() => this.$refs.invoicesTable.refresh());
    },
    downloadReport() {
      axios
        .get(`/api/brand/invoices/export`, {
          params: {
            from_date: this.filterDateFrom,
            to_date: this.filterDateTo,
            status: this.filterStatus,
          },
        })
        .then((response) => {
          this.downloadStarted = true;
        });
      setTimeout(() => {
        window.addEventListener('click', this.hidePopover);
      }, 100);
    },
  },
  created() {},
};
</script>
